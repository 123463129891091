import React, { useState } from "react";
import {
  TextInput,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  SelectInput,
  Button,
  useRefresh,
  SaveButton,
  Toolbar,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { NODE_API } from "../../../../config";
import { COUNTRY } from "../../../../constants/countries";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import MapModal from "../../../../layout/MapModal";

export const styles = {
  inline1: { display: "inline-block" },

  inline2: { display: "inline-block", marginLeft: 32 },

  normalWidth: { width: 544 },

  singleCol: { marginTop: "2em", marginBottom: "2em" },

  warningNote: { color: "#9F6000" },

  root: {
    paddingTop: 40,
  },

  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1em",
  },
  titleMobile: {
    justifyContent: "space-between",
    marginTop: "1%",
    marginLeft: "1%",
  },
};

const useStyles = makeStyles(styles);

const CreateLocation = ({
  onCancel,
  props,
  userList,
  sessionMailArray,
  organizationid,
}) => {
  const notify = useNotify();
  const [state, setState] = useState({});
  const refresh = useRefresh();
  const history = useHistory();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isMapOpen, setIsMapOpen] = useState(false); // To control map modal
  const [latLng, setLatLng] = useState(null);

  async function addNewLocation(locationAdmin) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify({
        address: state.address,
        city: state.city,
        name: state.locationname,
        country: state.country,
        adminmail: locationAdmin,
        user_id: state.userMail,
        latitude: state.latitude,
        longitude: state.longitude,
      }),
    };

    return axios(
      `${NODE_API}/organization/location/${organizationid}`,
      requestOptions
    )
      .then(async (res) => {
        if (res && res.status === 208) {
          notify(`Something went wrong try again later`, { type: "warning" });
          refresh();
          onCancel();
        } else if (res && res.status === 200) {
          notify(`Location added successfully`, { type: "success" });
          history.push("/organization/location/info");
          refresh();
          onCancel();
        } else if (res && res.status === 204) {
          notify(`Location already exists`, { type: "warning" });
          history.push("/organization/location/info");
          refresh();
          onCancel();
        } else {
          notify(`Something went wrong try again later`, { type: "warning" });
          history.push("/organization/location/info");
          refresh();
          onCancel();
        }

        // setState((state) => ({
        //   address: "",
        //   locationname: "",
        //   country: "",
        //   city: "",
        //   userMail: "",
        //   latitude: "",
        //   longitude: "",
        // }));
        refresh(true);

        return res;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
        history.push("/organization/location/info");
        onCancel();
      });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((state) => ({
      ...state,

      [name]: value,
    }));
  };

  const handleOpenMap = () => {
    setIsMapOpen(true);
  };

  // Function to handle closing the map modal
  const handleCloseMap = () => {
    setIsMapOpen(false);
  };

  const handleSelectCoordinates = (latitude, longitude) => {
    setLatLng(`${latitude?.toFixed(5)}, ${longitude?.toFixed(5)}`);

    setState((prevState) => ({
      ...prevState,
      latitude: latitude?.toFixed(7),
      longitude: longitude?.toFixed(7),
    }));
    handleCloseMap();
  };

  const ListActions = (props) => (
    <Toolbar {...props}>
      <SaveButton
        className="ml-2 p-1"
        handleSubmitWithRedirect={onsubmit}
        style={{ fontWeight: "bolder", padding: "6px 16px" }}
        alignIcon="left"
      />
      <Button
        className="ml-2 p-1"
        onClick={onCancel}
        style={{ fontWeight: "bolder", padding: "6px 16px" }}
        alignIcon="left"
        label="Cancel"
      >
        {<CancelOutlined />}
      </Button>
    </Toolbar>
  );

  const onsubmit = async (data) => {
    let locationAdmin;

    if (userList && userList.length > 0) {
      userList.map((element) => {
        if (element.user_id === state.userMail) {
          locationAdmin = element.email;
        }
      });
    } else {
      sessionMailArray.map((ele) => {
        locationAdmin = ele.name;
      });
    }

    await addNewLocation(locationAdmin);
  };

  return  (
    <>
      <div className="mt-5 p-0"></div>
      <Button
        className={`${classes.title} mb-0 `}
        style={{ color: "black" }}
        edge="start"
        onClick={onCancel}
      >
        <CloseIcon fontSize="large" />
      </Button>
      <SimpleForm redirect="list" toolbar={<ListActions />}>
        <div className="mt-2 p-3 font-weight-bold w-75 h4-responsive">
          Add New Location
        </div>

        <TextInput
          autoFocus
          source="locationname"
          label="name"
          required
          name="locationname"
          value={state.locationname}
          onChange={handleChange}
          formClassName={classes.inline1}
          validate={required()}
        />

        <TextInput
          source="city"
          label="city"
          required
          name="city"
          value={state.city}
          onChange={handleChange}
          formClassName={classes.inline2}
          validate={required()}
        />

        <SelectInput
          source="country"
          label="Select Country"
          choices={COUNTRY}
          required
          value={state.country}
          name="country"
          onChange={handleChange}
          fullWidth={true}
          formClassName={classes.normalWidth}
          validate={required()}
        />

        <TextInput
          source="address"
          label="address"
          fullWidth={true}
          value={state.address}
          required
          name="address"
          onChange={handleChange}
          formClassName={classes.normalWidth}
          validate={required()}
        />

        {!latLng ? (
          <TextInput
            source="latLng"
            label="Latitude, Longitude"
            required
            name="latLng"
            value={latLng}
            onClick={handleOpenMap} // Open map when clicked
            validate={required()}
            fullWidth
          />
        ) : (
          <div
            className="mb-2 my-2"
            style={{ cursor: "pointer" }}
            onClick={handleOpenMap}
          >
            {" "}
            Selected Latitude and Longitude: &nbsp;{" "}
            <span style={{ color: "#3f51b5" }}>{latLng}</span>
          </div>
        )}
        {isMapOpen && (
          <MapModal
            onClose={handleCloseMap}
            onSelectCoordinates={handleSelectCoordinates}
          />
        )}

        {userList && userList.length > 0 ? (
          <>
            <SelectInput
              choices={userList}
              source="userMail"
              name="userMail"
              required
              value={state.userMail}
              label="Select Mail"
              onChange={handleChange}
              fullWidth={true}
            />
          </>
        ) : (
          <>
            <SelectInput
              choices={sessionMailArray}
              source="userMail"
              name="userMail"
              label="Select Mail"
              value={state.userMail}
              required
              onChange={handleChange}
              fullWidth={true}
            />
            <h6 className="p-2 text-warning m-2">
              *No registered employee to add as a admin{" "}
            </h6>
          </>
        )}
      </SimpleForm>
    </>
  );
};

export default CreateLocation;

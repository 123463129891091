//Api startpoints

export const NODE_API = 'https://sovica.in/api'
export const USER_PROFILE_IMAGE = 'https://sovica.in/api/userImages'
export const Template_IMAGE_API = 'https://sovica.in/api/cardsImage/templates'
export const IMAGE_API = 'https://sovica.in/api/images'

// export const NODE_API = 'http://localhost:7979/api'
// export const USER_PROFILE_IMAGE = 'http://localhost:7979/api/userImages'
// export const Template_IMAGE_API = 'http://localhost:7979/api/cardsImage/templates'
// export const IMAGE_API = 'http://localhost:7979/api/images'

export const encryptOTPKey="sovica@otpvalid#$%"

import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  useDataProvider,
  useNotify,
  SelectInput,
  CheckboxGroupInput,
  SimpleForm,
  TextInput,
  ImageInput,
} from "react-admin";
import "../card.css";
import { NODE_API } from "../../../../../config";
import { Template_IMAGE_API } from "../../../../../config";
import axios from "axios";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { slideInRight } from "react-animations";
import Radium, { StyleRoot } from "radium";

const useStyles = makeStyles({
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em", width: "50%" },
  rightCol: { flex: 1, marginLeft: "1em", width: "50%" },
  marginLeft: { marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
  name: { display: "inline-block", marginLeft: "1em" },
  displayblock: { display: "inline-block" },
  inline1: { display: "inline-block", color: "black", width: "40%" },
  inline2: {
    display: "inline-block",
    color: "black",
    marginLeft: "3%",
    width: "40%",
  },
  inline3: { display: "inline-block", color: "black", width: "40%" },
});

//Local use variables
var boldboolean = 0,
  italicboolean = 0,
  underlineboolean = 0;
var bboldboolean = 0,
  bitalicboolean = 0,
  bunderlineboolean = 0;
var viewDesign = 0;
var nameboolean = 0,
  designationboolean = 0,
  empoyeeboolean = 0,
  titleboolean = 0,
  contactboolean = 0,
  imgboolean = 0,
  emailboolean = 0,
  addressboolean = 0,
  vlboolean = 0,
  hlboolean = 0,
  companynameboolean = 0,
  userprofileboolean = 0,
  regiaddressboolean = 0,
  regiaddress2boolean = 0,
  regiaddress3boolean = 0;

var bnameboolean = 0,
  bdesignationboolean = 0,
  bempoyeeboolean = 0,
  btitleboolean = 0,
  bcontactboolean = 0,
  bimgboolean = 0,
  bemailboolean = 0,
  baddressboolean = 0,
  bvlboolean = 0,
  bhlboolean = 0;
var arraycheck = [];
var userorganizationidd = "";

const styles = {
  flipInY: {
    animation: "s 3s",
    animationName: Radium.keyframes(slideInRight, "slideInRight"),
  },
};

//Front side view
const FrontView = (props) => {
  const [state, setState] = useState({});
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [arrayCheck, setArrayCheck] = useState([]);
  const [domainList, setDomainList] = useState([]);


  const getConstantDomain = async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token = user?.validator || {};

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    try {
      const response = await axios.get(`${NODE_API}/organization/domain-list`, requestOptions);
      console.log("res.data:", response.data);
      setDomainList(response.data); // Set the state with the fetched data
    } catch (e) {
      console.log("Fetch organization domain list error::", e);
    }
  };


    
    const checkDomainExists = async (domain) => {
   
      let user = JSON.parse(sessionStorage.getItem("user"));
      let token = user?.validator || {};

      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      try {
        const response = await axios.get(
          `${NODE_API}/organization/domain-list`,
          requestOptions
        );
        console.log("res.data in checkDomainExists:", response.data);
        setDomainList(response.data);
        const normalizedDomain = domain.trim().toLowerCase();
        console.log("normalizedDomain::", normalizedDomain);
        return response.data.some(
          (d) => d.trim().toLowerCase() === normalizedDomain
        );
      } catch (e) {
        console.log("Fetch organization domain list error::", e);
        return false;
      }
    };
    


  const userorganization = async function () {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    await axios(
      `${NODE_API}/organization/list/userorganization`,
      requestOptions
    )
      .then(async(res) => {
        var organizationlist = [];
       

        res.data.data.map(async(val) => {
    
          if (val.type_id == 2) {
            organizationlist.push({
              id: val.id,
              value: val.name,
              label: val.name,
              name: val.name,
            });

            userorganizationidd = val.id;
          }
          const check = await checkDomainExists(val.domain);
          if (check) {
            const div = document.getElementById("mydivlogo");
            div.style.boxShadow = "0 0 0 1px black, 0 0 0 3px white, 0 0 0 5px black";
          }
          
        });

        setState((state) => ({
          ...state,
          id: userorganizationidd,
          organizationlist: organizationlist,
        }));
      })
      .catch((e) => {
        console.log("userorganization error::", e);

        notify(`Something went wrong try again later ${e}`, {
          type: "warning",
        });
      });
  };

  //set front card design
  const setDesign = (design) => {
    if (design) {
      design.map(function (element) {
        if (element.isDisable != true) {
          var topvalue = element.position.top + "px";

          document.getElementById(element.position.name).style.top = topvalue;

          var leftvalue = element.position.left + "px";
          document.getElementById(element.position.name).style.left = leftvalue;

          if (element.name === "mydivvl") {
            document.getElementById("mydivvl").style.width =
              element.position.width + "px";
            document.getElementById("mydivvl").style.backgroundColor =
              element.position.backgroundColor;
          } else if (element.name === "mydivhl") {
            document.getElementById("mydivhl").style.height =
              element.position.height;
            document.getElementById("mydivhl").style.borderColor =
              element.position.borderColor;
          } else if (element.name != "logo" && element.name != "userprofile") {
            document.getElementById(element.name).innerHTML = element.value;
            document.getElementById(element.name).style.fontFamily =
              element.textStyle;
            document.getElementById(element.name).style.textDecoration =
              element.fontStyle.underline;
            document.getElementById(element.name).style.fontStyle =
              element.fontStyle.italic;
            document.getElementById(element.name).style.fontWeight =
              element.fontStyle.bold;
            document.getElementById(element.name).style.fontSize =
              element.fontSize;
            document.getElementById(element.name).style.color =
              element.fontColor;
          } else if (element.name == "userprofile") {
            document.getElementById(
              "mydivuserprofile"
            ).style.backgroundImage = `url(/images/img/avatar.png)`;

            document.getElementById("mydivuserprofile").style.width =
              element.position.width;
            document.getElementById("mydivuserprofile").style.height =
              element.position.height;
          } else {
            if (element.backgroundtemplate != null)
              document.getElementById(
                "card"
              ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            if (element.value != null)
              document.getElementById(
                "mydivlogo"
              ).style.backgroundImage = `url(${element.value})`;

            document.getElementById("mydivlogo").style.width =
              element.position.width;
            document.getElementById("mydivlogo").style.height =
              element.position.height;
          }
        } else {
          console.log("element.name::", element.name);

          if (element.name === "logo") {
            document.getElementById("mydivlogo").style.visibility = "hidden";
            setArrayCheck((prevArray) => [...prevArray, "imagecheck"]);
            arraycheck.push("imagecheck");
            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            imgboolean = 1;
          } else if (element.name === "userprofile") {
            document.getElementById("mydivuserprofile").style.visibility =
              "hidden";
            arraycheck.push("userprofilecheck");
            setArrayCheck((prevArray) => [...prevArray, "userprofilecheck"]);

            // document.getElementById(
            //   "card"
            // ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            userprofileboolean = 1;
          } else if (element.name === "addressset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("addresscheck");
            setArrayCheck((prevArray) => [...prevArray, "addresscheck"]);

            addressboolean = 1;
          } else if (element.name === "sloganset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("titlecheck");
            setArrayCheck((prevArray) => [...prevArray, "titlecheck"]);
            titleboolean = 1;
          } else if (element.name === "contactset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("contactcheck");
            setArrayCheck((prevArray) => [...prevArray, "contactcheck"]);
            contactboolean = 1;
          } else if (element.name === "emailset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("emailcheck");
            setArrayCheck((prevArray) => [...prevArray, "emailcheck"]);
            emailboolean = 1;
          } else if (element.name === "designationset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("designationsetcheck");
            setArrayCheck((prevArray) => [...prevArray, "designationsetcheck"]);
            designationboolean = 1;
          } else if (element.name === "empoyeeset") {
            document.getElementById("empoyeeset").style.visibility = "hidden";
            arraycheck.push("empoyeesetcheck");
            setArrayCheck((prevArray) => [...prevArray, "empoyeesetcheck"]);
            empoyeeboolean = 0;
          } else if (element.name === "nameset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("namecheck");
            setArrayCheck((prevArray) => [...prevArray, "namecheck"]);
            nameboolean = 1;
          } else if (element.name === "companynameset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("companynamecheck");
            setArrayCheck((prevArray) => [...prevArray, "companynamecheck"]);
            companynameboolean = 1;
          } else if (element.name === "regiaddressset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("regiaddresscheck");
            setArrayCheck((prevArray) => [...prevArray, "regiaddresscheck"]);
            regiaddressboolean = 1;
          } else if (element.name === "regiaddress2set") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("regiaddress2check");
            setArrayCheck((prevArray) => [...prevArray, "regiaddress2check"]);
            regiaddress2boolean = 1;
          } else if (element.name === "regiaddress3set") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("regiaddress3check");
            setArrayCheck((prevArray) => [...prevArray, "regiaddress3check"]);
            regiaddress3boolean = 1;
          } else if (element.name === "vlset") {
            document.getElementById("mydivvl").style.visibility = "hidden";
            arraycheck.push("vlcheck");
            setArrayCheck((prevArray) => [...prevArray, "vlcheck"]);
            vlboolean = 1;
          } else if (element.name === "hlset") {
            document.getElementById("mydivhl").style.visibility = "hidden";
            arraycheck.push("hlcheck");
            setArrayCheck((prevArray) => [...prevArray, "hlcheck"]);
            hlboolean = 1;
          }
        }
      });
    }
    return;
  };

  //Get use cards
  const fetchcards = useCallback(async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));

    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    await axios(
      `${NODE_API}/card/business/card/design/${userorganizationidd}`,
      requestOptions
    )
      .then((res) => {
        if (res && res.data[0]) {
          setDesign(res.data[0].front);
        } else return;
      })
      .catch((e) => {
        console.log("Fetch Cards error::", e);

        notify(`Something went wrong try again later ${e}`, {
          type: "warning",
        });
      });
  }, [dataProvider, arrayCheck]);

  const handleInputList = async (event) => {
    var idtarget = event.target.value;
    userorganizationidd = idtarget;
    setState((state) => ({
      ...state,
      id: idtarget,
    }));

    //changing the background
    const bgs = document.querySelectorAll(".backgrounds img");
    const card = document.querySelector(".card");
    let activeBg = 0; //default background

    setState((state) => ({
      ...state,

      getname: "nameset",
    }));

    bgs.forEach((item, i) => {
      item.addEventListener("click", () => {
        bgs[activeBg].classList.remove("active");
        item.classList.add("active");
        card.style.backgroundImage = `url('${item.src}')`;
        activeBg = i;
      });
    });

    // setting up logo
    const uploadBtn = document.querySelector("#upload");
    const mydivlogo = document.querySelector(".mydivlogo");

    uploadBtn.addEventListener("change", () => {
      if (uploadBtn.files && uploadBtn.files[0]) {
        let reader = new FileReader(); // init the file reader

        reader.addEventListener("load", () => {
          // reader.result will return the src of the uploaded image
          mydivlogo.style.backgroundImage = `url('${reader.result}')`;
        });

        reader.readAsDataURL(uploadBtn.files[0]);
      }
    });
    // setting background image
    const BackgroundTemplates = document.querySelector("#BackgroundTemplates");
    const BackgroundTemplatescard = document.querySelector("#card");

    BackgroundTemplates.addEventListener("change", () => {
      if (BackgroundTemplates.files && BackgroundTemplates.files[0]) {
        let reader = new FileReader(); // init the file reader

        reader.addEventListener("load", () => {
          // reader.result will return the src of the uploaded image
          BackgroundTemplatescard.style.backgroundImage = `url('${reader.result}')`;
        });

        reader.readAsDataURL(BackgroundTemplates.files[0]);
      }
    });

    //setting up slogan
    const sloganupdate = document.querySelector("#sloganupdate");

    sloganupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("sloganset").innerHTML = text;
    });

    // const companynameupdate = document.querySelector("#companynameupdate");
    // companynameupdate.addEventListener("input", (e) => {
    //   var text = e.target.value;
    //   document.getElementById("companynameset").innerHTML = text;
    // });

    //Hide and unhide fields
    const check = document.querySelector("#check");
    check.addEventListener("change", (e) => {
      if (e.target.value == "namecheck") {
        if (nameboolean === 0) {
          document.getElementById("nameset").style.visibility = "hidden";
          nameboolean = 1;
        } else {
          document.getElementById("nameset").style.visibility = "visible";
          nameboolean = 0;
        }
      }
      if (e.target.value == "companynamecheck") {
        if (companynameboolean === 0) {
          document.getElementById("companynameset").style.visibility = "hidden";
          companynameboolean = 1;
        } else {
          document.getElementById("companynameset").style.visibility =
            "visible";
          companynameboolean = 0;
        }
      }
      if (e.target.value == "regiaddresscheck") {
        if (regiaddressboolean === 0) {
          document.getElementById("regiaddressset").style.visibility = "hidden";
          regiaddressboolean = 1;
        } else {
          document.getElementById("regiaddressset").style.visibility =
            "visible";
          regiaddressboolean = 0;
        }
      }
      if (e.target.value == "regiaddress2check") {
        if (regiaddress2boolean === 0) {
          document.getElementById("regiaddress2set").style.visibility =
            "hidden";
          regiaddress2boolean = 1;
        } else {
          document.getElementById("regiaddress2set").style.visibility =
            "visible";
          regiaddress2boolean = 0;
        }
      }
      if (e.target.value == "regiaddress3check") {
        if (regiaddress3boolean === 0) {
          document.getElementById("regiaddress3set").style.visibility =
            "hidden";
          regiaddress3boolean = 1;
        } else {
          document.getElementById("regiaddress3set").style.visibility =
            "visible";
          regiaddress3boolean = 0;
        }
      }
      if (e.target.value == "designationsetcheck") {
        if (designationboolean === 0) {
          document.getElementById("designationset").style.visibility = "hidden";
          designationboolean = 1;
        } else {
          document.getElementById("designationset").style.visibility =
            "visible";
          designationboolean = 0;
        }
      }
      if (e.target.value == "empoyeesetcheck") {
        if (empoyeeboolean === 0) {
          document.getElementById("empoyeeset").style.visibility = "hidden";
          empoyeeboolean = 0;
        } else {
          document.getElementById("empoyeeset").style.visibility = "hidden";
          empoyeeboolean = 0;
        }
      }
      if (e.target.value == "titlecheck") {
        if (titleboolean === 0) {
          document.getElementById("sloganset").style.visibility = "hidden";
          titleboolean = 1;
        } else {
          document.getElementById("sloganset").style.visibility = "visible";
          titleboolean = 0;
        }
      }
      if (e.target.value == "contactcheck") {
        if (contactboolean === 0) {
          document.getElementById("contactset").style.visibility = "hidden";
          contactboolean = 1;
        } else {
          document.getElementById("contactset").style.visibility = "visible";
          contactboolean = 0;
        }
      }
      if (e.target.value == "emailcheck") {
        if (emailboolean === 0) {
          document.getElementById("emailset").style.visibility = "hidden";
          emailboolean = 1;
        } else {
          document.getElementById("emailset").style.visibility = "visible";
          emailboolean = 0;
        }
      }
      if (e.target.value == "imagecheck") {
        if (imgboolean === 0) {
          document.getElementById("mydivlogo").style.visibility = "hidden";
          imgboolean = 1;
        } else {
          document.getElementById("mydivlogo").style.visibility = "visible";
          imgboolean = 0;
        }
      }
      if (e.target.value == "userprofilecheck") {
        if (userprofileboolean === 0) {
          document.getElementById("mydivuserprofile").style.visibility =
            "hidden";
          userprofileboolean = 1;
        } else {
          document.getElementById("mydivuserprofile").style.visibility =
            "visible";
          userprofileboolean = 0;
        }
      }
      if (e.target.value == "addresscheck") {
        if (addressboolean === 0) {
          document.getElementById("addressset").style.visibility = "hidden";
          addressboolean = 1;
        } else {
          document.getElementById("addressset").style.visibility = "visible";
          addressboolean = 0;
        }
      }
      if (e.target.value == "vlcheck") {
        if (vlboolean === 0) {
          document.getElementById("mydivvl").style.visibility = "hidden";
          vlboolean = 1;
        } else {
          document.getElementById("mydivvl").style.visibility = "visible";
          vlboolean = 0;
        }
      }
      if (e.target.value == "hlcheck") {
        if (hlboolean === 0) {
          document.getElementById("mydivhl").style.visibility = "hidden";
          hlboolean = 1;
        } else {
          document.getElementById("mydivhl").style.visibility = "visible";
          hlboolean = 0;
        }
      }
    });

    //Make the DIV element draggable:
    dragElement(document.getElementById("mydivaddress"));
    dragElement(document.getElementById("mydivemail"));
    dragElement(document.getElementById("mydivname"));
    dragElement(document.getElementById("mydivcompanyname"));
    dragElement(document.getElementById("mydivregiaddress"));
    dragElement(document.getElementById("mydivregiaddress2"));
    dragElement(document.getElementById("mydivregiaddress3"));
    dragElement(document.getElementById("mydivslogan"));
    dragElement(document.getElementById("mydivcontact"));
    dragElement(document.getElementById("mydivdesignation"));
    dragElement(document.getElementById("mydivempoyeeid"));
    dragElement(document.getElementById("mydivvl"));
    dragElement(document.getElementById("mydivhl"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }

    initDragElement();
    initResizeElement();
    initDragUserProfileElement();
    initResizeUserProfileElement();

    function initDragElement() {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      var popups = document.getElementsByClassName("mydivlogo");
      var elmnt = null;
      var currentZIndex = 100; //TODO reset z index when a threshold is passed

      for (var i = 0; i < popups.length; i++) {
        var popup = popups[i];
        var header = getHeader(popup);

        popup.onmousedown = function () {
          this.style.zIndex = "" + ++currentZIndex;
        };

        if (header) {
          header.parentPopup = popup;
          header.onmousedown = dragMouseDown;
        }
      }

      function dragMouseDown(e) {
        elmnt = this.parentPopup;
        elmnt.style.zIndex = "" + ++currentZIndex;

        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        if (!elmnt) {
          return;
        }

        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }

      function getHeader(element) {
        var headerItems = element.getElementsByClassName("logo");

        if (headerItems.length === 1) {
          return headerItems[0];
        }

        return null;
      }
    }

    function initResizeElement() {
      var popups = document.getElementsByClassName("mydivlogo");
      var element = null;
      var startX, startY, startWidth, startHeight;

      for (var i = 0; i < popups.length; i++) {
        var p = popups[i];

        var right = document.createElement("div");
        right.className = "resizer-right";
        p.appendChild(right);
        right.addEventListener("mousedown", initDrag, false);
        right.parentPopup = p;

        var bottom = document.createElement("div");
        bottom.className = "resizer-bottom";
        p.appendChild(bottom);
        bottom.addEventListener("mousedown", initDrag, false);
        bottom.parentPopup = p;

        var both = document.createElement("div");
        both.className = "resizer-both";
        p.appendChild(both);
        both.addEventListener("mousedown", initDrag, false);
        both.parentPopup = p;
      }

      function initDrag(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10
        );
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10
        );
        document.documentElement.addEventListener("mousemove", doDrag, false);
        document.documentElement.addEventListener("mouseup", stopDrag, false);
      }

      function doDrag(e) {
        element.style.width = startWidth + e.clientX - startX + "px";
        element.style.height = startHeight + e.clientY - startY + "px";
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          "mousemove",
          doDrag,
          false
        );
        document.documentElement.removeEventListener(
          "mouseup",
          stopDrag,
          false
        );
      }
    }

    function initDragUserProfileElement() {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      var popups = document.getElementsByClassName("mydivuserprofile");
      var elmnt = null;
      var currentZIndex = 100; //TODO reset z index when a threshold is passed

      for (var i = 0; i < popups.length; i++) {
        var popup = popups[i];
        var header = getHeader(popup);

        popup.onmousedown = function () {
          this.style.zIndex = "" + ++currentZIndex;
        };

        if (header) {
          header.parentPopup = popup;
          header.onmousedown = dragMouseDown;
        }
      }

      function dragMouseDown(e) {
        elmnt = this.parentPopup;
        elmnt.style.zIndex = "" + ++currentZIndex;

        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        if (!elmnt) {
          return;
        }

        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }

      function getHeader(element) {
        var headerItems = element.getElementsByClassName("userprofile");

        if (headerItems.length === 1) {
          return headerItems[0];
        }

        return null;
      }
    }

    function initResizeUserProfileElement() {
      var popups = document.getElementsByClassName("mydivuserprofile");
      var element = null;
      var startX, startY, startWidth, startHeight;

      for (var i = 0; i < popups.length; i++) {
        var p = popups[i];

        var right = document.createElement("div");
        right.className = "resizer-right";
        p.appendChild(right);
        right.addEventListener("mousedown", initDrag, false);
        right.parentPopup = p;

        var bottom = document.createElement("div");
        bottom.className = "resizer-bottom";
        p.appendChild(bottom);
        bottom.addEventListener("mousedown", initDrag, false);
        bottom.parentPopup = p;

        var both = document.createElement("div");
        both.className = "resizer-both";
        p.appendChild(both);
        both.addEventListener("mousedown", initDrag, false);
        both.parentPopup = p;
      }

      function initDrag(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10
        );
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10
        );
        document.documentElement.addEventListener("mousemove", doDrag, false);
        document.documentElement.addEventListener("mouseup", stopDrag, false);
      }

      function doDrag(e) {
        element.style.width = startWidth + e.clientX - startX + "px";
        element.style.height = startHeight + e.clientY - startY + "px";
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          "mousemove",
          doDrag,
          false
        );
        document.documentElement.removeEventListener(
          "mouseup",
          stopDrag,
          false
        );
      }
    }
    fetchcards();
  };

  useEffect(() => {
    getConstantDomain();
    userorganization().then(async(res) => {
      //changing the background
      const bgs = document.querySelectorAll(".backgrounds img");
      const card = document.querySelector(".card");
      let activeBg = 0; //default background

      setState((state) => ({
        ...state,

        getname: "nameset",
      }));

      bgs.forEach((item, i) => {
        item.addEventListener("click", () => {
          bgs[activeBg].classList.remove("active");
          item.classList.add("active");
          card.style.backgroundImage = `url('${item.src}')`;
          activeBg = i;
        });
      });

      // setting up logo
      const uploadBtn = document.querySelector("#upload");
      const mydivlogo = document.querySelector(".mydivlogo");

      uploadBtn.addEventListener("change", () => {
        if (uploadBtn.files && uploadBtn.files[0]) {
          let reader = new FileReader(); // init the file reader

          reader.addEventListener("load", () => {
            // reader.result will return the src of the uploaded image
            mydivlogo.style.backgroundImage = `url('${reader.result}')`;
          });

          reader.readAsDataURL(uploadBtn.files[0]);
        }
      });
      // setting background image
      const BackgroundTemplates = document.querySelector(
        "#BackgroundTemplates"
      );
      const BackgroundTemplatescard = document.querySelector("#card");

      BackgroundTemplates.addEventListener("change", () => {
        if (BackgroundTemplates.files && BackgroundTemplates.files[0]) {
          // alert("hii")

          let reader = new FileReader(); // init the file reader

          reader.addEventListener("load", () => {
            // reader.result will return the src of the uploaded image
            BackgroundTemplatescard.style.backgroundImage = `url('${reader.result}')`;
          });

          reader.readAsDataURL(BackgroundTemplates.files[0]);
        }
      });

      //setting up slogan
      const sloganupdate = document.querySelector("#sloganupdate");

      sloganupdate.addEventListener("input", (e) => {
        var text = e.target.value;
        document.getElementById("sloganset").innerHTML = text;
      });

      // adding company name update
      const companynameupdate = document.querySelector("#companynameupdate");

      companynameupdate.addEventListener("input", (e) => {
        var text = e.target.value;
        document.getElementById("companynameset").innerHTML = text;
      });

      const regiaddressupdate = document.querySelector("#regiaddressupdate");

      regiaddressupdate.addEventListener("input", (e) => {
        var text = e.target.value;
        document.getElementById("regiaddressset").innerHTML = text;
      });

      const regiaddress2update = document.querySelector("#regiaddress2update");

      regiaddress2update.addEventListener("input", (e) => {
        var text = e.target.value;
        document.getElementById("regiaddress2set").innerHTML = text;
      });

      const regiaddress3update = document.querySelector("#regiaddress3update");

      regiaddress3update.addEventListener("input", (e) => {
        var text = e.target.value;
        document.getElementById("regiaddress3set").innerHTML = text;
      });

      //hide and unhide fields
      const check = document.querySelector("#check");
      check.addEventListener("change", (e) => {
        if (e.target.value == "namecheck") {
          if (nameboolean === 0) {
            document.getElementById("nameset").style.visibility = "hidden";
            nameboolean = 1;
          } else {
            document.getElementById("nameset").style.visibility = "visible";
            nameboolean = 0;
          }
        }

        if (e.target.value == "companynamecheck") {
          if (companynameboolean === 0) {
            document.getElementById("companynameset").style.visibility =
              "hidden";
            companynameboolean = 1;
          } else {
            document.getElementById("companynameset").style.visibility =
              "visible";
            companynameboolean = 0;
          }
        }

        if (e.target.value == "regiaddresscheck") {
          if (regiaddressboolean === 0) {
            document.getElementById("regiaddressset").style.visibility =
              "hidden";
            regiaddressboolean = 1;
          } else {
            document.getElementById("regiaddressset").style.visibility =
              "visible";
            regiaddressboolean = 0;
          }
        }

        if (e.target.value == "regiaddress2check") {
          if (regiaddress2boolean === 0) {
            document.getElementById("regiaddress2set").style.visibility =
              "hidden";
            regiaddress2boolean = 1;
          } else {
            document.getElementById("regiaddress2set").style.visibility =
              "visible";
            regiaddress2boolean = 0;
          }
        }

        if (e.target.value == "regiaddress3check") {
          if (regiaddress3boolean === 0) {
            document.getElementById("regiaddress3set").style.visibility =
              "hidden";
            regiaddress3boolean = 1;
          } else {
            document.getElementById("regiaddress3set").style.visibility =
              "visible";
            regiaddress3boolean = 0;
          }
        }

        if (e.target.value == "designationsetcheck") {
          if (designationboolean === 0) {
            document.getElementById("designationset").style.visibility =
              "hidden";
            designationboolean = 1;
          } else {
            document.getElementById("designationset").style.visibility =
              "visible";
            designationboolean = 0;
          }
        }
        if (e.target.value == "empoyeesetcheck") {
          if (empoyeeboolean === 0) {
            document.getElementById("empoyeeset").style.visibility = "hidden";
            empoyeeboolean = 0;
          } else {
            document.getElementById("empoyeeset").style.visibility = "hidden";
            empoyeeboolean = 0;
          }
        }
        if (e.target.value == "titlecheck") {
          if (titleboolean === 0) {
            document.getElementById("sloganset").style.visibility = "hidden";
            titleboolean = 1;
          } else {
            document.getElementById("sloganset").style.visibility = "visible";
            titleboolean = 0;
          }
        }
        if (e.target.value == "contactcheck") {
          if (contactboolean === 0) {
            document.getElementById("contactset").style.visibility = "hidden";
            contactboolean = 1;
          } else {
            document.getElementById("contactset").style.visibility = "visible";
            contactboolean = 0;
          }
        }
        if (e.target.value == "emailcheck") {
          if (emailboolean === 0) {
            document.getElementById("emailset").style.visibility = "hidden";
            emailboolean = 1;
          } else {
            document.getElementById("emailset").style.visibility = "visible";
            emailboolean = 0;
          }
        }
        if (e.target.value == "imagecheck") {
          if (imgboolean === 0) {
            document.getElementById("mydivlogo").style.visibility = "hidden";
            imgboolean = 1;
          } else {
            document.getElementById("mydivlogo").style.visibility = "visible";
            imgboolean = 0;
          }
        }
        if (e.target.value == "userprofilecheck") {
          if (userprofileboolean === 0) {
            document.getElementById("mydivuserprofile").style.visibility =
              "hidden";
            userprofileboolean = 1;
          } else {
            document.getElementById("mydivuserprofile").style.visibility =
              "visible";
            userprofileboolean = 0;
          }
        }
        if (e.target.value == "addresscheck") {
          if (addressboolean === 0) {
            document.getElementById("addressset").style.visibility = "hidden";
            addressboolean = 1;
          } else {
            document.getElementById("addressset").style.visibility = "visible";
            addressboolean = 0;
          }
        }
        if (e.target.value == "vlcheck") {
          if (vlboolean === 0) {
            document.getElementById("mydivvl").style.visibility = "hidden";
            vlboolean = 1;
          } else {
            document.getElementById("mydivvl").style.visibility = "visible";
            vlboolean = 0;
          }
        }
        if (e.target.value == "hlcheck") {
          if (hlboolean === 0) {
            document.getElementById("mydivhl").style.visibility = "hidden";
            hlboolean = 1;
          } else {
            document.getElementById("mydivhl").style.visibility = "visible";
            hlboolean = 0;
          }
        }
      });

      //Make the DIV element draggagle:
      dragElement(document.getElementById("mydivaddress"));
      dragElement(document.getElementById("mydivemail"));
      dragElement(document.getElementById("mydivname"));
      dragElement(document.getElementById("mydivcompanyname"));
      dragElement(document.getElementById("mydivregiaddress"));
      dragElement(document.getElementById("mydivregiaddress2"));
      dragElement(document.getElementById("mydivregiaddress3"));
      dragElement(document.getElementById("mydivslogan"));
      dragElement(document.getElementById("mydivcontact"));
      dragElement(document.getElementById("mydivdesignation"));
      dragElement(document.getElementById("mydivempoyeeid"));
      dragElement(document.getElementById("mydivvl"));
      dragElement(document.getElementById("mydivhl"));

      function dragElement(elmnt) {
        var pos1 = 0,
          pos2 = 0,
          pos3 = 0,
          pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
          /* if present, the header is where you move the DIV from:*/
          document.getElementById(elmnt.id + "header").onmousedown =
            dragMouseDown;
        } else {
          /* otherwise, move the DIV from anywhere inside the DIV:*/
          elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }

        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }

      initDragElement();
      initResizeElement();
      initDragUserProfileElement();
      initResizeUserProfileElement();

      function initDragElement() {
        var pos1 = 0,
          pos2 = 0,
          pos3 = 0,
          pos4 = 0;
        var popups = document.getElementsByClassName("mydivlogo");
        var elmnt = null;
        var currentZIndex = 100; //TODO reset z index when a threshold is passed

        for (var i = 0; i < popups.length; i++) {
          var popup = popups[i];
          var header = getHeader(popup);

          popup.onmousedown = function () {
            this.style.zIndex = "" + ++currentZIndex;
          };

          if (header) {
            header.parentPopup = popup;
            header.onmousedown = dragMouseDown;
          }
        }

        function dragMouseDown(e) {
          elmnt = this.parentPopup;
          elmnt.style.zIndex = "" + ++currentZIndex;

          e = e || window.event;
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
          if (!elmnt) {
            return;
          }

          e = e || window.event;
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }

        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
        }

        function getHeader(element) {
          var headerItems = element.getElementsByClassName("logo");

          if (headerItems.length === 1) {
            return headerItems[0];
          }

          return null;
        }
      }

      function initResizeElement() {
        var popups = document.getElementsByClassName("mydivlogo");
        var element = null;
        var startX, startY, startWidth, startHeight;

        for (var i = 0; i < popups.length; i++) {
          var p = popups[i];

          var right = document.createElement("div");
          right.className = "resizer-right";
          p.appendChild(right);
          right.addEventListener("mousedown", initDrag, false);
          right.parentPopup = p;

          var bottom = document.createElement("div");
          bottom.className = "resizer-bottom";
          p.appendChild(bottom);
          bottom.addEventListener("mousedown", initDrag, false);
          bottom.parentPopup = p;

          var both = document.createElement("div");
          both.className = "resizer-both";
          p.appendChild(both);
          both.addEventListener("mousedown", initDrag, false);
          both.parentPopup = p;
        }

        function initDrag(e) {
          element = this.parentPopup;

          startX = e.clientX;
          startY = e.clientY;
          startWidth = parseInt(
            document.defaultView.getComputedStyle(element).width,
            10
          );

          startHeight = parseInt(
            document.defaultView.getComputedStyle(element).height,
            10
          );

          document.documentElement.addEventListener("mousemove", doDrag, false);
          document.documentElement.addEventListener("mouseup", stopDrag, false);
        }

        function doDrag(e) {
          element.style.width = startWidth + e.clientX - startX + "px";
          element.style.height = startHeight + e.clientY - startY + "px";
        }

        function stopDrag() {
          document.documentElement.removeEventListener(
            "mousemove",
            doDrag,
            false
          );
          document.documentElement.removeEventListener(
            "mouseup",
            stopDrag,
            false
          );
        }
      }

      function initDragUserProfileElement() {
        var pos1 = 0,
          pos2 = 0,
          pos3 = 0,
          pos4 = 0;
        var popups = document.getElementsByClassName("mydivuserprofile");
        var elmnt = null;
        var currentZIndex = 100; //TODO reset z index when a threshold is passed

        for (var i = 0; i < popups.length; i++) {
          var popup = popups[i];
          var header = getHeader(popup);

          popup.onmousedown = function () {
            this.style.zIndex = "" + ++currentZIndex;
          };

          if (header) {
            header.parentPopup = popup;
            header.onmousedown = dragMouseDown;
          }
        }

        function dragMouseDown(e) {
          elmnt = this.parentPopup;
          elmnt.style.zIndex = "" + ++currentZIndex;

          e = e || window.event;
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
          if (!elmnt) {
            return;
          }

          e = e || window.event;
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }

        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
        }

        function getHeader(element) {
          var headerItems = element.getElementsByClassName("userprofile");

          if (headerItems.length === 1) {
            return headerItems[0];
          }

          return null;
        }
      }

      function initResizeUserProfileElement() {
        var popups = document.getElementsByClassName("mydivuserprofile");
        var element = null;
        var startX, startY, startWidth, startHeight;

        for (var i = 0; i < popups.length; i++) {
          var p = popups[i];

          var right = document.createElement("div");
          right.className = "resizer-right";
          p.appendChild(right);
          right.addEventListener("mousedown", initDrag, false);
          right.parentPopup = p;

          var bottom = document.createElement("div");
          bottom.className = "resizer-bottom";
          p.appendChild(bottom);
          bottom.addEventListener("mousedown", initDrag, false);
          bottom.parentPopup = p;

          var both = document.createElement("div");
          both.className = "resizer-both";
          p.appendChild(both);
          both.addEventListener("mousedown", initDrag, false);
          both.parentPopup = p;
        }

        function initDrag(e) {
          element = this.parentPopup;

          startX = e.clientX;
          startY = e.clientY;
          startWidth = parseInt(
            document.defaultView.getComputedStyle(element).width,
            10
          );

          startHeight = parseInt(
            document.defaultView.getComputedStyle(element).height,
            10
          );

          document.documentElement.addEventListener("mousemove", doDrag, false);
          document.documentElement.addEventListener("mouseup", stopDrag, false);
        }

        function doDrag(e) {
          element.style.width = startWidth + e.clientX - startX + "px";
          element.style.height = startHeight + e.clientY - startY + "px";
        }

        function stopDrag() {
          document.documentElement.removeEventListener(
            "mousemove",
            doDrag,
            false
          );
          document.documentElement.removeEventListener(
            "mouseup",
            stopDrag,
            false
          );
        }
      }

      fetchcards();
    });
  }, []);

  const handleInput = (e) => {
    const value = e.target.value;

    const fontFamilies = [
      "Serif",
      "Sans-serif",
      "Monospace",
      "Cursive",
      "Arial",
      "Helvetica",
      "Georgia",
      "Times New Roman",
      "Courier New",
      "Verdana",
      "Trebuchet MS",
      "Palatino",
      "Garamond",
      "Impact",
      "Comic Sans MS",
    ];

    if (
      [
        "nameset",
        "companynameset",
        "sloganset",
        "contactset",
        "emailset",
        "designationset",
        "empoyeeset",
        "addressset",
        "mydivvl",
        "mydivhl",
        "regiaddressset",
        "regiaddress2set",
        "regiaddress3set",

      ].includes(value)
    ) {
      setState((state) => ({
        ...state,
        getname: value,
      }));
      boldboolean = 0;
      italicboolean = 0;
      underlineboolean = 0;
    } else if (fontFamilies.includes(value)) {
      setState((state) => ({
        ...state,
        getfontstyle: value,
      }));

      if (state.getname) {
        document.getElementById(state.getname).style.fontFamily = value;
      }
    } else if (["bolder", "underline", "italic"].includes(value)) {
      setState((state) => ({
        ...state,
        getteststyle: value,
      }));

      if (state.getname) {
        const element = document.getElementById(state.getname);

        if (value === "underline") {
          if (underlineboolean === 0) {
            element.style.textDecoration = "underline";
            underlineboolean = 1;
          } else {
            element.style.textDecoration = "none";
            underlineboolean = 0;
          }
        } else if (value === "italic") {
          if (italicboolean === 0) {
            element.style.fontStyle = "italic";
            italicboolean = 1;
          } else {
            element.style.fontStyle = "normal";
            italicboolean = 0;
          }
        } else if (value === "bolder") {
          if (boldboolean === 0) {
            element.style.fontWeight = "900";
            boldboolean = 1;
          } else {
            element.style.fontWeight = "normal";
            boldboolean = 0;
          }
        }
      }
    }
  };

  const handleInputSize = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      var size = e.target.value + "%";
      document.getElementById(state.getname).style.width = size;
    } else if (state.getname && state.getname == "mydivhl") {
      var size = e.target.value + "%";
      document.getElementById(state.getname).style.height = size;
    } else if (state.getname) {
      var size = e.target.value + "px";
      document.getElementById(state.getname).style.fontSize = size;
    }
  };
  const handleInputcolor = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      document.getElementById(state.getname).style.backgroundColor =
        e.target.value;
    } else if (state.getname && state.getname == "mydivhl") {
      document.getElementById(state.getname).style.borderColor = e.target.value;
    } else if (state.getname) {
      document.getElementById(state.getname).style.color = e.target.value;
    }
  };

  const download_img = (el) => {
    var elementid = document.getElementById("nameset");
    var parentid = document.getElementById("mydivname");
    var namedata = getdetails(
      "nameset",
      "mydivname",
      parentid,
      elementid,
      nameboolean
    );

    var elementid = document.getElementById("companynameset");
    var parentid = document.getElementById("mydivcompanyname");
    var companynamedata = getdetails(
      "companynameset",
      "mydivcompanyname",
      parentid,
      elementid,
      companynameboolean
    );

    var elementid = document.getElementById("regiaddressset");
    var parentid = document.getElementById("mydivregiaddress");
    var regiaddressdata = getdetails(
      "regiaddressset",
      "mydivregiaddress",
      parentid,
      elementid,
      regiaddressboolean
    );

    var elementid = document.getElementById("regiaddress2set");
    var parentid = document.getElementById("mydivregiaddress2");
    var regiaddress2data = getdetails(
      "regiaddress2set",
      "mydivregiaddress2",
      parentid,
      elementid,
      regiaddress2boolean
    );

    var elementid = document.getElementById("regiaddress3set");
    var parentid = document.getElementById("mydivregiaddress3");
    var regiaddress3data = getdetails(
      "regiaddress3set",
      "mydivregiaddress3",
      parentid,
      elementid,
      regiaddress3boolean
    );

    elementid = document.getElementById("sloganset");
    parentid = document.getElementById("mydivslogan");
    var slogandata = getdetails(
      "sloganset",
      "mydivslogan",
      parentid,
      elementid,
      titleboolean
    );

    elementid = document.getElementById("contactset");
    parentid = document.getElementById("mydivcontact");
    var contactdata = getdetails(
      "contactset",
      "mydivcontact",
      parentid,
      elementid,
      contactboolean
    );

    elementid = document.getElementById("addressset");
    parentid = document.getElementById("mydivaddress");
    var addressdata = getdetails(
      "addressset",
      "mydivaddress",
      parentid,
      elementid,
      addressboolean
    );

    elementid = document.getElementById("emailset");
    parentid = document.getElementById("mydivemail");
    var emaildata = getdetails(
      "emailset",
      "mydivemail",
      parentid,
      elementid,
      emailboolean
    );

    elementid = document.getElementById("designationset");
    parentid = document.getElementById("mydivdesignation");
    var designationdata = getdetails(
      "designationset",
      "mydivdesignation",
      parentid,
      elementid,
      designationboolean
    );

    elementid = document.getElementById("empoyeeset");
    parentid = document.getElementById("mydivempoyeeid");
    var eployeeiddata = getdetails(
      "empoyeeset",
      "mydivempoyeeid",
      parentid,
      elementid,
      true
    );

    elementid = document.getElementById("logo");
    parentid = document.getElementById("mydivlogo");
    var logodata = getdetails(
      "logo",
      "mydivlogo",
      parentid,
      elementid,
      imgboolean
    );

    elementid = document.getElementById("userprofile");
    parentid = document.getElementById("mydivuserprofile");
    var userprofiledata = getdetails(
      "userprofile",
      "mydivuserprofile",
      parentid,
      elementid,
      userprofileboolean
    );

    elementid = document.getElementById("vlset");
    parentid = document.getElementById("mydivvl");
    var verticallinedata = getdetails(
      "vlset",
      "mydivvl",
      parentid,
      elementid,
      vlboolean
    );

    elementid = document.getElementById("hlset");
    parentid = document.getElementById("mydivhl");
    var horizontallinedata = getdetails(
      "hlset",
      "mydivhl",
      parentid,
      elementid,
      hlboolean
    );

    var jsonData = [];

    jsonData.push(namedata);
    jsonData.push(companynamedata);
    jsonData.push(regiaddressdata);
    jsonData.push(regiaddress2data);
    jsonData.push(regiaddress3data);
    jsonData.push(slogandata);
    jsonData.push(contactdata);
    jsonData.push(addressdata);
    jsonData.push(emaildata);
    jsonData.push(designationdata);
    jsonData.push(eployeeiddata);
    jsonData.push(logodata);
    jsonData.push(userprofiledata);
    jsonData.push(verticallinedata);
    jsonData.push(horizontallinedata);

    createcard(jsonData);
  };

  function getdetails(
    elementname,
    elementparentname,
    parentid,
    elementid,
    disable
  ) {
    if (disable == 1) {
      if (elementname == "logo") {
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");

        var data = {
          name: elementname,
          isDisable: true,
          backgroundtemplate: backimage,
        };
      } else if (elementname == "userprofile") {
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");

        var data = {
          name: elementname,
          isDisable: true,
          backgroundtemplate: backimage,
        };
      } else {
        var data = {
          name: elementname,
          isDisable: true,
        };
      }
      return data;
    } else {
      if (elementname == "logo") {
        let compStyles = window.getComputedStyle(parentid);
        var getImage = compStyles.getPropertyValue("background-image");

        if (getImage.includes("base64")) {
          var image = getImage
            .replace("url(", "")
            .replace(")", "")
            .replaceAll('"', "");
        } else {
          var image = null;
        }
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");

        var data = {
          backgroundtemplate: backimage,
          name: elementname,
          isDisable: false,
          value: image,
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            width: `${parentid.offsetWidth}px`,
          },
        };
        return data;
      } else if (elementname == "userprofile") {
        let compStyles = window.getComputedStyle(parentid);

        var data = {
          name: elementname,
          isDisable: false,
          value: "",
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            width: `${parentid.offsetWidth}px`,
          },
        };
        return data;
      } else if (elementname == "vlset") {
        let compStyles = window.getComputedStyle(parentid);

        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            width: parentid.offsetWidth,
            backgroundColor: compStyles.getPropertyValue("background-color"),
          },
        };

        return data;
      } else if (elementname == "hlset") {
        let compStyles = window.getComputedStyle(parentid);

        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            borderColor: compStyles.getPropertyValue("border-color"),
          },
        };
        return data;
      } else {
        let compStyles = window.getComputedStyle(elementid);
        var data = {
          name: elementname,
          isDisable: false,
          value: elementid.innerHTML,
          textStyle: compStyles.getPropertyValue("font-family"),
          fontStyle: {
            bold: compStyles.getPropertyValue("font-weight"),
            italic: compStyles.getPropertyValue("font-style"),
            underline: compStyles.getPropertyValue("text-decoration"),
          },
          fontSize: compStyles.getPropertyValue("font-size"),
          fontColor: compStyles.getPropertyValue("color"),
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
          },
        };
        return data;
      }
    }
  }

  async function createcard(cardData) {
    let user = JSON.parse(sessionStorage.getItem("user"));

    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify({
        cardData: cardData,
      }),
    };

    return axios(
      `${NODE_API}/card/business/design/${userorganizationidd}`,
      requestOptions
    )
      .then(async (res) => {
        if (res.data.status && res.data.status === "Successfully Added") {
          notify(`Template successfully design`, { type: "success" });
        } else {
          notify(`Something went wrong try again later ${res.data}`, {
            type: "warning",
          });
        }
      })
      .catch((e) => {
        console.log("Create Cards error::", e);

        notify(`Something went wrong try again later ${e}`, {
          type: "warning",
        });
      });
  }
  return (
    <div className="main">
      <SimpleForm toolbar={false} className="setting">
        <ImageInput
          id="upload"
          source="cardlogo"
          label={<span style={{ fontWeight: 'bold', color: 'black', fontSize:24, marginLeft:20 }}>Logo</span>}
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a logo to upload, or click to select it.logo should not greater than 50kb"
        ></ImageInput>

        {/* this is the beginning */}
        <CheckboxGroupInput
          id="check"
          initialValue={arraycheck}
          formClassName={classes.name}
          source="disabled"
          label={<span style={{ fontWeight: 'bold', color: 'black', fontSize:24 }}>Show/Hide</span>}
          choices={[
            { id: "namecheck", name: "name" },
            { id: "companynamecheck", name: "Company Name" },
            { id: "titlecheck", name: "Punch Line" },
            { id: "contactcheck", name: "contact" },
            { id: "emailcheck", name: "email" },
            { id: "imagecheck", name: "image" },
            { id: "addresscheck", name: "address" },
            // { id: "empoyeesetcheck", name: "empoyeeid" },
            { id: "designationsetcheck", name: "designation" },
            { id: "vlcheck", name: "horizontal line" },
            { id: "hlcheck", name: "vertical line" },
            { id: "userprofilecheck", name: "Profile Image" },
            { id: "regiaddresscheck", name: "Registered Address" },
            { id: "regiaddress2check", name: "Registered Address 2" },
            { id: "regiaddress3check", name: "Registered Address 3" },
          ]}
        />

        {/* <Typography variant="h7" gutterBottom>
          Background Templates
        </Typography> */}
        <span style={{ fontWeight: 'bold', color: 'black', fontSize:20, marginLeft:18 }}>Background Templates</span>

        <span style={{ color: 'black', fontSize:14, marginLeft:18 }}>Plain Background Templates</span>
        <div className="backgrounds" style={{ width: "100%" }}>

          <img src={`${Template_IMAGE_API}/plain0_w.png`} className="active" alt=""/>
          <img src={`${Template_IMAGE_API}/plain1_o.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain2_r.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain3_lp.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain4_y.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain5_b.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain6_lg.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain7_dg.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain8_p.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain9_pd.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain10_pid.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain11_b.jpg`} alt="" />
        </div>
        <span style={{ color: 'black', fontSize:14, marginLeft:18 }}>Graphic Background Templates</span>
        <div className="backgrounds" style={{ width: "100%" }}>

          <img
            src={`${Template_IMAGE_API}/img0.png`}
            className="active"
            alt=""
          />
          <img src={`${Template_IMAGE_API}/img1.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/img2.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img3.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img4.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img5.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img6.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img7.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img8.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img9.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img10.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img11.png`} alt="" />
        </div>

        <ImageInput
          id="BackgroundTemplates"
          source="BackgroundTemplates"
          label={<span style={{ fontWeight: 600, color: 'black', fontSize:18, marginLeft:20 }}>Custom Background Templates</span>}
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a templateimage to upload, or click to select it.image should not greater than 50kb"
        ></ImageInput>
      </SimpleForm>
      <div className="setting">
        {state.organizationlist && state.organizationlist.length > 1 ? (
          <SimpleForm toolbar={false}>
            <SelectInput
              placeholder="Select organization"
              source="orgSelection"
              label="Select organization"
              fullWidth={true}
              required={true}
              defaultValue={state.id}
              onChange={handleInputList}
              style={{ width: "500px" }}
              choices={state.organizationlist}
            />
          </SimpleForm>
        ) : (
          <></>
        )}

        <StyleRoot>
          <div className="card" id="card" style={[styles.flipInY]}>
            <span
              id="mydivlogo"
              className="mydivlogo"
              style={{ top: "20px", left: "26px" }}
            >
              <div className="logo" id="logo"></div>
            </span>
            <span
              id="mydivuserprofile"
              className="mydivuserprofile"
              style={{ top: "20px", right: "20px" }}
            >
              <div className="userprofile" id="userprofile"></div>
            </span>

            <span id="mydivcompanyname" style={{ top: "20px", left: "190px" }}>
              <p className="companyname" id="companynameset">
                Company Name
              </p>
            </span>
            <span id="mydivslogan" style={{ top: "43px", left: "192px" }}>
              <p className="slogan" id="sloganset">
                Punch Line
              </p>
            </span>

            <span id="mydivname" style={{ top: "110px", left: "40px" }}>
              <p className="name" id="nameset">
                Name
              </p>
            </span>
            <span id="mydivdesignation" style={{ top: "130px", left: "40px" }}>
              <p className="designation" id="designationset">
                Designation
              </p>
            </span>

            <span id="mydivcontact" style={{ top: "165px", left: "40px" }}>
              <p className="contact" id="contactset">
                Contact Number
              </p>
            </span>
            <span id="mydivemail" style={{ top: "185px", left: "40px" }}>
              <p className="email" id="emailset">
                Email Address
              </p>
            </span>

            <span id="mydivempoyeeid" style={{ top: "252px", left: "18px" }}>
              <p
                className="empoyee"
                id="empoyeeset"
                style={{ visibility: "hidden" }}
              >
                empoyeeid
              </p>
            </span>
            <span id="mydivaddress" style={{ top: "263px", left: "18px" }}>
              <p className="address" id="addressset">
                Location Address
              </p>
            </span>

            <span id="mydivhl" style={{ bottom: "10px", left: "230px" }}>
              <hr className="hl" id="hlset" />
            </span>
            <span id="mydivvl" style={{ bottom: "70px", right: "20px" }}>
              <p className="vl" id="vlset"></p>
            </span>
            <span
              id="mydivregiaddress"
              style={{ bottom: "30px", left: "20px" }}
            >
              <p className="regiaddress" id="regiaddressset">
                Registered Address 1
              </p>
            </span>
            <span
              id="mydivregiaddress2"
              style={{ bottom: "30px", right: "20px" }}
            >
              <p className="regiaddress2" id="regiaddress2set">
                Registered Address 2
              </p>
            </span>
            <span
              id="mydivregiaddress3"
              style={{ bottom: "15px", right: "20px" }}
            >
              <p className="regiaddress3" id="regiaddress3set">
                Registered Address 3
              </p>
            </span>
          </div>
        </StyleRoot>
        <SimpleForm save={download_img}>
          <SelectInput
            id="getforstyle"
            defaultValue={"nameset"}
            onChange={handleInput}
            source="Input Fields"
            formClassName={classes.inline1}
            choices={[
              { id: "nameset", name: "name" },
              { id: "companynameset", name: "company name" },
              { id: "sloganset", name: "punch line" },
              { id: "contactset", name: "contact" },
              { id: "emailset", name: "email" },
              { id: "addressset", name: "address" },
              // { id: "empoyeeset", name: "empoyeeid" },
              { id: "designationset", name: "designation" },
              { id: "mydivvl", name: "horizontal line" },
              { id: "mydivhl", name: "vertical line" },
              { id: "userprofilecheck", name: "Profile Image" },
              { id: "regiaddressset", name: "Registered Address 1" },
              { id: "regiaddress2set", name: "Registered Address 2" },
              { id: "regiaddress3set", name: "Registered Address 3" },

            ]}
          />
          <TextInput
            source="companyname"
            id="companynameupdate"
            label="Company name on the card"
            formClassName={classes.inline2}
          />
          <SelectInput
            id="gettextstyle"
            onChange={handleInput}
            source="TextStyle"
            formClassName={classes.inline1}
            choices={[
              { id: "Serif", name: "Serif" },
              { id: "Sans-serif", name: "Sans-serif" },
              { id: "Monospace", name: "Monospace" },
              { id: "Cursive", name: "Cursive" },
              { id: "Arial", name: "Arial" },
              { id: "Georgia", name: "Georgia" },
              { id: "Times New Roman", name: "Times New Roman" },
              { id: "Courier New", name: "Courier New" },
              { id: "Trebuchet MS", name: "Trebuchet MS" },
              { id: "Palatino", name: "Palatino" },
              { id: "Garamond", name: "Garamond" },
              { id: "Impact", name: "Impact" },
            ]}
          />
          <TextInput
            source="cardtite"
            id="sloganupdate"
            label="Punch line on the card"
            formClassName={classes.inline2}
          />
          <SelectInput
            onChange={handleInput}
            formClassName={classes.inline3}
            source="Font"
            choices={[
              { id: "bolder", name: "Bold" },
              { id: "italic", name: "Italic" },
              { id: "underline", name: "Underline" },
            ]}
          />
          <TextInput
            source="regiaddress"
            id="regiaddressupdate"
            label="Registered address 1"
            formClassName={classes.inline2}
          />
          <TextInput
            onChange={handleInputSize}
            formClassName={classes.inline1}
            id="fontupdate"
            source="font"
            label="Enter element size in px"
            type="number"
          />

          <TextInput
            source="regiaddress2"
            id="regiaddress2update"
            label="Registered address 2"
            formClassName={classes.inline2}
          />
          <TextInput
            onChange={handleInputcolor}
            id="colorupdate"
            source="colour"
            label="Click to select colour"
            formClassName={classes.inline1}
            type="color"
          />
          <TextInput
            source="regiaddress3"
            id="regiaddress3update"
            label="Registered address 3"
            formClassName={classes.inline2}
          />
        </SimpleForm>
      </div>
    </div>
  );
};
const BackView = (props) => {
  const [state, setState] = useState({});
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const setDesign = (design) => {
    if (design) {
      design.map(function (element) {
        if (element.isDisable != true) {
          var topvalue = element.position.top + "px";

          document.getElementById(element.position.name).style.top = topvalue;

          var leftvalue = element.position.left + "px";
          document.getElementById(element.position.name).style.left = leftvalue;

          if (element.name === "mydivvl") {
            document.getElementById("mydivvl").style.width =
              element.position.width + "px";
            document.getElementById("mydivvl").style.backgroundColor =
              element.position.backgroundColor;
          } else if (element.name === "mydivhl") {
            document.getElementById("mydivhl").style.height =
              element.position.height;
            document.getElementById("mydivhl").style.borderColor =
              element.position.borderColor;
          } else if (element.name != "logo") {
            document.getElementById(element.name).innerHTML = element.value;
            document.getElementById(element.name).style.fontFamily =
              element.textStyle;
            document.getElementById(element.name).style.textDecoration =
              element.fontStyle.underline;
            document.getElementById(element.name).style.fontStyle =
              element.fontStyle.italic;
            document.getElementById(element.name).style.fontWeight =
              element.fontStyle.bold;
            document.getElementById(element.name).style.fontSize =
              element.fontSize;
            document.getElementById(element.name).style.color =
              element.fontColor;
          } else {
            if (element.backgroundtemplate != null)
              document.getElementById(
                "card"
              ).style.backgroundImage = `url(${element.backgroundtemplate})`;

            if (element.value != null)
              document.getElementById(
                "mydivlogo"
              ).style.backgroundImage = `url(${element.value})`;
            document.getElementById("mydivlogo").style.width =
              element.position.width;
            document.getElementById("mydivlogo").style.height =
              element.position.height;
          }
        } else {
          if (element.name === "logo") {
            document.getElementById("mydivlogo").style.visibility = "hidden";
            document.getElementById(
              "card"
            ).style.backgroundImage = `url(${element.backgroundtemplate})`;
            arraycheck.push("imagecheck");

            bimgboolean = 1;
          } else if (element.name === "addressset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("addresscheck");

            baddressboolean = 1;
          } else if (element.name === "sloganset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("titlecheck");

            btitleboolean = 1;
          } else if (element.name === "contactset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("contactcheck");

            bcontactboolean = 1;
          } else if (element.name === "emailset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("emailcheck");

            bemailboolean = 1;
          } else if (element.name === "nameset") {
            document.getElementById(element.name).style.visibility = "hidden";
            arraycheck.push("namecheck");

            bnameboolean = 1;
          } else if (element.name === "vlset") {
            document.getElementById("mydivvl").style.visibility = "hidden";
            arraycheck.push("vlcheck");

            bvlboolean = 1;
          } else if (element.name === "hlset") {
            document.getElementById("mydivhl").style.visibility = "hidden";
            arraycheck.push("hlcheck");

            bhlboolean = 1;
          }
        }
      });
    }
    return;
  };
  //Get list of organizations
  const fetchcards = useCallback(async () => {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;
    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    // requestOptions
    await axios(
      `${NODE_API}/card/business/card/design/${userorganizationidd}`,
      requestOptions
    )
      .then((res) => {
        if (res && res.data[0]) {
          setDesign(res.data[0].back);
        } else return;
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  }, [dataProvider]);
  useEffect(() => {
    //changing the background
    const bgs = document.querySelectorAll(".backgrounds img");
    const card = document.querySelector(".card");
    let activeBg = 0; //default background

    setState((state) => ({
      ...state,

      getname: "nameset",
    }));

    bgs.forEach((item, i) => {
      item.addEventListener("click", () => {
        bgs[activeBg].classList.remove("active");
        item.classList.add("active");
        card.style.backgroundImage = `url('${item.src}')`;
        activeBg = i;
      });
    });

    // setting up logo
    const uploadBtn = document.querySelector("#upload");
    const mydivlogo = document.querySelector(".mydivlogo");
    uploadBtn.addEventListener("change", () => {
      if (uploadBtn.files && uploadBtn.files[0]) {
        let reader = new FileReader(); // init the file reader

        reader.addEventListener("load", () => {
          // reader.result will return the src of the uploaded image
          mydivlogo.style.backgroundImage = `url('${reader.result}')`;
        });
        reader.readAsDataURL(uploadBtn.files[0]);
      }
    });
    // setting background image
    const BackgroundTemplates = document.querySelector("#BackgroundTemplates");
    const BackgroundTemplatescard = document.querySelector("#card");
    BackgroundTemplates.addEventListener("change", () => {
      if (BackgroundTemplates.files && BackgroundTemplates.files[0]) {
        let reader = new FileReader(); // init the file reader
        reader.addEventListener("load", () => {
          // reader.result will return the src of the uploaded image
          BackgroundTemplatescard.style.backgroundImage = `url('${reader.result}')`;
        });

        reader.readAsDataURL(BackgroundTemplates.files[0]);
      }
    });
    //setting up name
    const cardname = document.querySelector("#cardnameupdate");
    cardname.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("nameset").innerHTML = text;
    });

    //setting up slogan
    const sloganupdate = document.querySelector("#sloganupdate");

    sloganupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("sloganset").innerHTML = text;
    });

    //setting up contact
    const contactupdate = document.querySelector("#contactupdate");
    contactupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("contactset").innerHTML = text;
    });

    //setting up address
    const addressupdate = document.querySelector("#addressupdate");
    addressupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("addressset").innerHTML = text;
    });

    //setting up email
    const emailupdate = document.querySelector("#emailupdate");
    emailupdate.addEventListener("input", (e) => {
      var text = e.target.value;
      document.getElementById("emailset").innerHTML = text;
    });

    //hide and unhide fields
    const check = document.querySelector("#check");
    check.addEventListener("change", (e) => {
      if (e.target.value == "namecheck") {
        if (bnameboolean === 0) {
          document.getElementById("nameset").style.visibility = "hidden";
          bnameboolean = 1;
        } else {
          document.getElementById("nameset").style.visibility = "visible";
          bnameboolean = 0;
        }
      }
      if (e.target.value == "titlecheck") {
        if (btitleboolean === 0) {
          document.getElementById("sloganset").style.visibility = "hidden";
          btitleboolean = 1;
        } else {
          document.getElementById("sloganset").style.visibility = "visible";
          btitleboolean = 0;
        }
      }
      if (e.target.value == "contactcheck") {
        if (bcontactboolean === 0) {
          document.getElementById("contactset").style.visibility = "hidden";
          bcontactboolean = 1;
        } else {
          document.getElementById("contactset").style.visibility = "visible";
          bcontactboolean = 0;
        }
      }
      if (e.target.value == "emailcheck") {
        if (bemailboolean === 0) {
          document.getElementById("emailset").style.visibility = "hidden";
          bemailboolean = 1;
        } else {
          document.getElementById("emailset").style.visibility = "visible";
          bemailboolean = 0;
        }
      }
      if (e.target.value == "imagecheck") {
        if (bimgboolean === 0) {
          document.getElementById("mydivlogo").style.visibility = "hidden";
          bimgboolean = 1;
          // alert(imgboolean)
        } else {
          document.getElementById("mydivlogo").style.visibility = "visible";
          bimgboolean = 0;
        }
      }
      if (e.target.value == "addresscheck") {
        if (baddressboolean === 0) {
          document.getElementById("addressset").style.visibility = "hidden";
          baddressboolean = 1;
        } else {
          document.getElementById("addressset").style.visibility = "visible";
          baddressboolean = 0;
        }
      }
      if (e.target.value == "vlcheck") {
        if (bvlboolean === 0) {
          document.getElementById("mydivvl").style.visibility = "hidden";
          bvlboolean = 1;
        } else {
          document.getElementById("mydivvl").style.visibility = "visible";
          bvlboolean = 0;
        }
      }
      if (e.target.value == "hlcheck") {
        if (bhlboolean === 0) {
          document.getElementById("mydivhl").style.visibility = "hidden";
          bhlboolean = 1;
        } else {
          document.getElementById("mydivhl").style.visibility = "visible";
          bhlboolean = 0;
        }
      }
    });

    //Make the DIV element draggagle:
    dragElement(document.getElementById("mydivaddress"));
    dragElement(document.getElementById("mydivemail"));
    dragElement(document.getElementById("mydivname"));
    dragElement(document.getElementById("mydivslogan"));
    dragElement(document.getElementById("mydivcontact"));
    dragElement(document.getElementById("mydivvl"));
    dragElement(document.getElementById("mydivhl"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown =
          dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    // window.onload = function() {
    initDragElement();
    initResizeElement();
    //   };

    function initDragElement() {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      var popups = document.getElementsByClassName("mydivlogo");
      var elmnt = null;
      var currentZIndex = 100; //TODO reset z index when a threshold is passed

      for (var i = 0; i < popups.length; i++) {
        var popup = popups[i];
        var header = getHeader(popup);

        popup.onmousedown = function () {
          this.style.zIndex = "" + ++currentZIndex;
        };

        if (header) {
          header.parentPopup = popup;
          header.onmousedown = dragMouseDown;
        }
      }

      function dragMouseDown(e) {
        elmnt = this.parentPopup;
        elmnt.style.zIndex = "" + ++currentZIndex;

        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        if (!elmnt) {
          return;
        }

        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }

      function getHeader(element) {
        var headerItems = element.getElementsByClassName("logo");

        if (headerItems.length === 1) {
          return headerItems[0];
        }

        return null;
      }
    }

    function initResizeElement() {
      var popups = document.getElementsByClassName("mydivlogo");
      var element = null;
      var startX, startY, startWidth, startHeight;

      for (var i = 0; i < popups.length; i++) {
        var p = popups[i];

        var right = document.createElement("div");
        right.className = "resizer-right";
        p.appendChild(right);
        right.addEventListener("mousedown", initDrag, false);
        right.parentPopup = p;

        var bottom = document.createElement("div");
        bottom.className = "resizer-bottom";
        p.appendChild(bottom);
        bottom.addEventListener("mousedown", initDrag, false);
        bottom.parentPopup = p;

        var both = document.createElement("div");
        both.className = "resizer-both";
        p.appendChild(both);
        both.addEventListener("mousedown", initDrag, false);
        both.parentPopup = p;
      }

      function initDrag(e) {
        element = this.parentPopup;

        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(
          document.defaultView.getComputedStyle(element).width,
          10
        );
        startHeight = parseInt(
          document.defaultView.getComputedStyle(element).height,
          10
        );
        document.documentElement.addEventListener("mousemove", doDrag, false);
        document.documentElement.addEventListener("mouseup", stopDrag, false);
      }

      function doDrag(e) {
        element.style.width = startWidth + e.clientX - startX + "px";
        element.style.height = startHeight + e.clientY - startY + "px";
      }

      function stopDrag() {
        document.documentElement.removeEventListener(
          "mousemove",
          doDrag,
          false
        );
        document.documentElement.removeEventListener(
          "mouseup",
          stopDrag,
          false
        );
      }
    }
    fetchcards();
  }, []);

  const handleInput = (e) => {
    const value = e.target.value;

    const fontFamilies = [
      "Serif",
      "Sans-serif",
      "Monospace",
      "Cursive",
      "Arial",
      "Helvetica",
      "Georgia",
      "Times New Roman",
      "Courier New",
      "Verdana",
      "Trebuchet MS",
      "Palatino",
      "Garamond",
      "Impact",
      "Comic Sans MS",
    ];

    if (
      [
        "nameset",
        "companynameset",
        "sloganset",
        "contactset",
        "emailset",
        "designationset",
        "empoyeeset",
        "addressset",
        "mydivvl",
        "mydivhl",
      ].includes(value)
    ) {
      setState((state) => ({
        ...state,
        getname: value,
      }));
      boldboolean = 0;
      italicboolean = 0;
      underlineboolean = 0;
    } else if (fontFamilies.includes(value)) {
      setState((state) => ({
        ...state,
        getfontstyle: value,
      }));

      if (state.getname) {
        document.getElementById(state.getname).style.fontFamily = value;
      }
    } else if (["bolder", "underline", "italic"].includes(value)) {
      setState((state) => ({
        ...state,
        getteststyle: value,
      }));

      if (state.getname) {
        const element = document.getElementById(state.getname);

        if (value === "underline") {
          if (underlineboolean === 0) {
            element.style.textDecoration = "underline";
            underlineboolean = 1;
          } else {
            element.style.textDecoration = "none";
            underlineboolean = 0;
          }
        } else if (value === "italic") {
          if (italicboolean === 0) {
            element.style.fontStyle = "italic";
            italicboolean = 1;
          } else {
            element.style.fontStyle = "normal";
            italicboolean = 0;
          }
        } else if (value === "bolder") {
          if (boldboolean === 0) {
            element.style.fontWeight = "900";
            boldboolean = 1;
          } else {
            element.style.fontWeight = "normal";
            boldboolean = 0;
          }
        }
      }
    }
  };

  const handleInputSize = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      var size = e.target.value + "%";

      document.getElementById(state.getname).style.width = size;
    } else if (state.getname && state.getname == "mydivhl") {
      var size = e.target.value + "%";
      document.getElementById(state.getname).style.height = size;
    } else if (state.getname) {
      var size = e.target.value + "px";
      document.getElementById(state.getname).style.fontSize = size;
    }
  };
  const handleInputcolor = (e) => {
    if (state.getname && state.getname == "mydivvl") {
      document.getElementById(state.getname).style.backgroundColor =
        e.target.value;
    } else if (state.getname && state.getname == "mydivhl") {
      document.getElementById(state.getname).style.borderColor = e.target.value;
    } else if (state.getname) {
      document.getElementById(state.getname).style.color = e.target.value;
    }
  };

  const download_img = (el) => {
    var elementid = document.getElementById("nameset");
    var parentid = document.getElementById("mydivname");
    var namedata = getdetails(
      "nameset",
      "mydivname",
      parentid,
      elementid,
      bnameboolean
    );

    elementid = document.getElementById("sloganset");
    parentid = document.getElementById("mydivslogan");
    var slogandata = getdetails(
      "sloganset",
      "mydivslogan",
      parentid,
      elementid,
      btitleboolean
    );

    elementid = document.getElementById("contactset");
    parentid = document.getElementById("mydivcontact");
    var contactdata = getdetails(
      "contactset",
      "mydivcontact",
      parentid,
      elementid,
      bcontactboolean
    );

    elementid = document.getElementById("addressset");
    parentid = document.getElementById("mydivaddress");
    var addressdata = getdetails(
      "addressset",
      "mydivaddress",
      parentid,
      elementid,
      baddressboolean
    );

    elementid = document.getElementById("emailset");
    parentid = document.getElementById("mydivemail");
    var emaildata = getdetails(
      "emailset",
      "mydivemail",
      parentid,
      elementid,
      bemailboolean
    );

    elementid = document.getElementById("logo");
    parentid = document.getElementById("mydivlogo");
    var logodata = getdetails(
      "logo",
      "mydivlogo",
      parentid,
      elementid,
      bimgboolean
    );

    elementid = document.getElementById("vlset");
    parentid = document.getElementById("mydivvl");
    var verticallinedata = getdetails(
      "vlset",
      "mydivvl",
      parentid,
      elementid,
      bvlboolean
    );

    elementid = document.getElementById("hlset");
    parentid = document.getElementById("mydivhl");
    var horizontallinedata = getdetails(
      "hlset",
      "mydivhl",
      parentid,
      elementid,
      bhlboolean
    );

    var jsonData = [];

    jsonData.push(namedata);
    jsonData.push(slogandata);
    jsonData.push(contactdata);
    jsonData.push(addressdata);
    jsonData.push(emaildata);
    jsonData.push(logodata);
    jsonData.push(verticallinedata);
    jsonData.push(horizontallinedata);

    createcard(jsonData);
  };

  function getdetails(
    elementname,
    elementparentname,
    parentid,
    elementid,
    disable
  ) {
    if (disable == 1) {
      if (elementname == "logo") {
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        // alert(getbackgroundtemplate)

        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");

        var data = {
          name: elementname,
          isDisable: true,
          backgroundtemplate: backimage,
        };
      } else {
        var data = {
          name: elementname,
          isDisable: true,
        };
      }
      return data;
    } else {
      if (elementname == "logo") {
        let compStyles = window.getComputedStyle(parentid);
        var getImage = compStyles.getPropertyValue("background-image");

        if (getImage.includes("base64")) {
          var image = getImage
            .replace("url(", "")
            .replace(")", "")
            .replaceAll('"', "");
        } else {
          var image = null;
        }
        var cardtemplate = document.getElementById("card");

        let backgroundtemplateStyles = window.getComputedStyle(cardtemplate);
        var getbackgroundtemplate =
          backgroundtemplateStyles.getPropertyValue("background-image");
        // alert(getbackgroundtemplate)

        var backimage = getbackgroundtemplate
          .replace("url(", "")
          .replace(")", "")
          .replaceAll('"', "");

        var data = {
          backgroundtemplate: backimage,
          name: elementname,
          isDisable: false,
          value: image,
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            width: `${parentid.offsetWidth}px`,
          },
        };
        return data;
      } else if (elementname == "vlset") {
        let compStyles = window.getComputedStyle(parentid);
        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            width: parentid.offsetWidth,
            backgroundColor: compStyles.getPropertyValue("background-color"),
          },
        };
        return data;
      } else if (elementname == "hlset") {
        let compStyles = window.getComputedStyle(parentid);
        var data = {
          name: elementparentname,
          isDisable: false,

          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
            height: compStyles.getPropertyValue("height"),
            borderColor: compStyles.getPropertyValue("border-color"),
          },
        };
        return data;
      } else {
        let compStyles = window.getComputedStyle(elementid);
        var data = {
          name: elementname,
          isDisable: false,
          value: elementid.innerHTML,
          textStyle: compStyles.getPropertyValue("font-family"),
          fontStyle: {
            bold: compStyles.getPropertyValue("font-weight"),
            italic: compStyles.getPropertyValue("font-style"),
            underline: compStyles.getPropertyValue("text-decoration"),
          },
          fontSize: compStyles.getPropertyValue("font-size"),
          fontColor: compStyles.getPropertyValue("color"),
          position: {
            name: elementparentname,
            left: parentid.offsetLeft,
            top: parentid.offsetTop,
          },
        };
        return data;
      }
    }
  }

  async function createcard(cardData) {
    let user = JSON.parse(sessionStorage.getItem("user"));
    let token;

    if (user && user.validator) {
      token = user.validator;
    } else {
      token = {};
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: JSON.stringify({
        cardData: cardData,
      }),
    };

    return axios(
      `${NODE_API}/card/business/design/back/${userorganizationidd}`,
      requestOptions
    )
      .then(async (res) => {
        if (res.data.status && res.data.status === "Successfully Added") {
          notify(`Template successfully designed`, { type: "success" });
        } else {
          notify(`Something went wrong try again later`, { type: "warning" });
        }
      })
      .catch((e) => {
        notify(`Something went wrong try again later`, { type: "warning" });
      });
  }
  return (
    <div className="main">
      <SimpleForm toolbar={false} className="setting">
        <TextInput
          autoFocus
          id="cardnameupdate"
          source="cardname"
          label="Text 1"
          formClassName={classes.name}
        />
        <TextInput
          source="cardtite"
          id="sloganupdate"
          label="Text 2"
          formClassName={classes.name}
        />

        <TextInput
          id="emailupdate"
          source="cardemail"
          label="Text 3"
          formClassName={classes.name}
        />
        <TextInput
          source="cardcontactno"
          label="Text 4"
          id="contactupdate"
          formClassName={classes.name}
        />

        <TextInput
          source="addresscard"
          label="Text 5"
          id="addressupdate"
          formClassName={classes.name}
        />

        <ImageInput
          id="upload"
          source="cardlogo"
          accept="image/png"
          label={
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: 24,
                marginLeft: 20,
              }}
            >
              Logo
            </span>
          }
          maxSize="50000"
          placeholder="Drop a logo to upload, or click to select it.logo should not greater than 50kb"
        ></ImageInput>

        <CheckboxGroupInput
          id="check"
          initialValue={arraycheck}
          formClassName={classes.name}
          source="disabled"
          label={
            <span style={{ fontWeight: "bold", color: "black", fontSize: 24 }}>
              Show/Hide
            </span>
          }
          choices={[
            { id: "namecheck", name: "Text 1" },
            { id: "titlecheck", name: "Text 2" },
            { id: "emailcheck", name: "Text 3" },
            { id: "contactcheck", name: "Text 4" },
            { id: "addresscheck", name: "Text 5" },
            { id: "imagecheck", name: "image" },
            { id: "vlcheck", name: "horizontal line" },
            { id: "hlcheck", name: "vertical line" },
          ]}
        />

        <span
          style={{
            fontWeight: "bold",
            color: "black",
            fontSize: 20,
            marginLeft: 18,
          }}
        >
          Background Templates
        </span>

        <span style={{ color: "black", fontSize: 14, marginLeft: 18 }}>
          Plain Background Templates
        </span>
        <div className="backgrounds" style={{ width: "100%" }}>
          <img
            src={`${Template_IMAGE_API}/plain0_w.png`}
            className="active"
            alt=""
          />
          <img src={`${Template_IMAGE_API}/plain1_o.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain2_r.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain3_lp.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain4_y.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain5_b.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain6_lg.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain7_dg.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain8_p.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain9_pd.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain10_pid.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/plain11_b.jpg`} alt="" />
        </div>
        <span style={{ color: "black", fontSize: 14, marginLeft: 18 }}>
          Graphic Background Templates
        </span>
        <div className="backgrounds" style={{ width: "100%" }}>
          <img
            src={`${Template_IMAGE_API}/img0.png`}
            className="active"
            alt=""
          />
          <img src={`${Template_IMAGE_API}/img1.jpg`} alt="" />
          <img src={`${Template_IMAGE_API}/img2.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img3.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img4.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img5.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img6.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img7.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img8.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img9.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img10.png`} alt="" />
          <img src={`${Template_IMAGE_API}/img11.png`} alt="" />
        </div>
        <ImageInput
          id="BackgroundTemplates"
          source="BackgroundTemplates"
          label={<span style={{ fontWeight: 600, color: 'black', fontSize:18, marginLeft:20 }}>Custom Background Templates</span>}
          accept="image/png"
          maxSize="50000"
          placeholder="Drop a template image to upload, or click to select it.image should not greater than 50kb"
        ></ImageInput>
      </SimpleForm>
      <div className="setting">
        <StyleRoot>
          <div className="card" id="card" style={styles.flipInY}>
            <span
              id="mydivlogo"
              className="mydivlogo"
              style={{ top: "20px", left: "26px" }}
            >
              <div className="logo" id="logo"></div>
            </span>
            <span id="mydivname" style={{ top: "20px", left: "210px" }}>
              <p className="name" id="nameset">
                Text 1
              </p>
            </span>
            <span id="mydivslogan" style={{ top: "45px", left: "210px" }}>
              <p className="slogan" id="sloganset">
                Text 2
              </p>
            </span>
            <span id="mydivemail" style={{ top: "65px", left: "210px" }}>
              <p className="email" id="emailset">
                Text 3
              </p>
            </span>
            <span id="mydivcontact" style={{ top: "85px", left: "210px" }}>
              <p className="contact" id="contactset">
                Text 4
              </p>
            </span>

            <span id="mydivaddress" style={{ top: "105px", left: "210px" }}>
              <p className="address" id="addressset">
                Text 5
              </p>
            </span>

            <span id="mydivhl" style={{ bottom: "10px", left: "230px" }}>
              <hr className="hl" id="hlset" />
            </span>
            <span id="mydivvl" style={{ bottom: "70px", right: "20px" }}>
              <p className="vl" id="vlset"></p>
            </span>
          </div>
        </StyleRoot>

        <SimpleForm save={download_img}>
          <SelectInput
            id="getforstyle"
            defaultValue={"nameset"}
            onChange={handleInput}
            source="Input Fields"
            formClassName={classes.displayblock}
            choices={[
              { id: "nameset", name: "Text 1" },
              { id: "sloganset", name: "Text 2" },
              { id: "emailset", name: "Text 3" },
              { id: "contactset", name: "Text 4" },
              { id: "addressset", name: "Text 5" },
              { id: "mydivvl", name: "horizontal line" },
              { id: "mydivhl", name: "vertical line" },
            ]}
          />
          <SelectInput
            id="gettextstyle"
            onChange={handleInput}
            source="TextStyle"
            formClassName={classes.name}
            choices={[
              { id: "Serif", name: "Serif" },
              { id: "Sans-serif", name: "Sans-serif" },
              { id: "Monospace", name: "Monospace" },
              { id: "Cursive", name: "Cursive" },
              { id: "Arial", name: "Arial" },
              { id: "Georgia", name: "Georgia" },
              { id: "Times New Roman", name: "Times New Roman" },
              { id: "Courier New", name: "Courier New" },
              { id: "Trebuchet MS", name: "Trebuchet MS" },
              { id: "Palatino", name: "Palatino" },
              { id: "Garamond", name: "Garamond" },
              { id: "Impact", name: "Impact" },
            ]}
          />

          <SelectInput
            onChange={handleInput}
            source="Font"
            choices={[
              { id: "bolder", name: "bold" },
              { id: "italic", name: "italic" },
              { id: "underline", name: "underline" },
            ]}
          />

          <TextInput
            onChange={handleInputSize}
            formClassName={classes.displayblock}
            id="fontupdate"
            source="font"
            label="Enter element size in px"
            type="number"
          />
          <TextInput
            onChange={handleInputcolor}
            id="colorupdate"
            source="colour"
            label="Click to select colour"
            formClassName={classes.name}
            type="color"
          />
        </SimpleForm>
      </div>
    </div>
  );
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const CorporateCard = (props) => {
  const [state, setState] = React.useState({
    checkedC: true,
  });

  const handleChange = (event) => {
    if (viewDesign === 0) {
      viewDesign = 1;
    } else {
      viewDesign = 0;
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <>
      <FormGroup style={{ display: "block", margin: "5px 100px 10px 5px" }}>
        <Typography
          component="div"
          style={{ width: "fit-content", float: "right" }}
        >
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Backview</Grid>
            <Grid item>
              <AntSwitch
                checked={state.checkedC}
                onChange={handleChange}
                name="checkedC"
              />
            </Grid>
            <Grid item>Frontview</Grid>
          </Grid>
        </Typography>
      </FormGroup>
      {viewDesign === 0 ? <FrontView /> : <BackView />}
    </>
  );
};

export default CorporateCard;
